import React, { useState } from "react"
import Layout from "../../components/layout"
import { Link, navigate } from "gatsby"
import "./wholesale-login.scss"
import { gql, HttpLink, useMutation } from '@apollo/client';
import { randomString } from "../../helpers/randomString";
import Loading from "../../components/Loading";
import { setAuth } from "../../utils/function";
import Seo from "../../components/seo";


const loginData = gql`
mutation Login($input: LoginInput!) {
  login(input: $input) {
    authToken
    clientMutationId
    refreshToken
    sessionToken
    customer{
      id
      databaseId
      shipping{
        address1
        address2
      }
    }
    user {
      userId
      nickname
     email
     firstName
     id
     lastName
     name
     username
     databaseId
     slug
     uri
    }
  }
}
`
function WholesaleLogin() {
  const [username, setUserName] = useState("")
  const [password, setPassword] = useState("")
  const seo = {
    title:"Wholesale Log In Page - Backa Australia",
    description:""
  }
  const [submitLoginData, { data, loading, error }] = useMutation(loginData, {
    onCompleted: (data) => {
      const { login } = data;
      if (login) {
        const authData = {
          authToken: login.authToken,
          user: login.user.username,
          userID: login.user.userId,
          customerID: login.customer.id
        };
        setAuth(authData);
        sessionStorage.setItem("isLogged", true)
        navigate("/my-account")
      }
    },
    onError: err => {
      console.log(err)
      alert('Your username or password incorrectly. Please re-enter')
    }
  })


  return (
    <Layout>
      {loading && <div>
        <Loading />
      </div>
      }
      <Seo title={seo.title} description={seo.description}/>
      <div className="login section">
        <div className="container">
          <form
            className="login__form"
            onSubmit={e => {
              e.preventDefault()
              submitLoginData(
                {
                  variables: {
                    input: {
                      username: username,
                      password: password,
                      clientMutationId: randomString()
                    }
                  }
                })
            }
            }
          >
            <div className="input-wrapper full-mb half">
              <label htmlFor="">
                Username or email address
                <abbr title="required">*</abbr>
              </label>
              <input type="text" name="" id="" required
                onChange={(e) => setUserName(e.target.value)}
              />
            </div>
            <div className="input-wrapper full-mb half">
              <label htmlFor="">
                Password
                <abbr title="required">*</abbr>
              </label>
              <input type="password" name="" id="" required
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="input-wrapper full-mb half checkbox">
              <input type="checkbox" name="remmember" id="remmember" />
              <label htmlFor="remmember">
                Remember Me
              </label>
            </div>
            <div className="input-wrapper full-mb half input-wrapper--submit">
              <button type="submit" value="Login" disabled={loading}>Login</button>
            </div>
          </form>
          <div className="login-links">
            <Link to="/wholesale-registration">Register</Link>
            <Link to="/forgot-password">Lost your password?</Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default WholesaleLogin
